@import "styles/_variables.scss";

.card {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid #DFDEDF;
    border-radius: 4px;
    width: 100%;

    &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: $mainText;
        margin-left: 20px;
        max-width: 70%;
    }
}